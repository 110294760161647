/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { resetLoginData } from '../../actions/login';
import LoginRoute from './LoginRoute';
import history from '../../core/history';
import { apiGetDispatchable } from '../../core/dapi';
import { getGroupLocationsUrl, getSolvAndUclMergedLocationUrl } from '../../core/dapi/location';
import { getInvoiceLocationUrl } from '../../core/dapi/invoices';
import { noOp } from '../../actions';
import { setActiveLocation, locationError } from '../../actions/location';
import { saveNewInvoiceSource } from '../../actions/invoices';
import {
  fetchingGroupLocations,
  receiveGroupLocations,
  groupLocationsError,
} from '../../actions/groupLocations';

import LoadingScreen from '../../components/SolvPatternLibrary/LoadingScreen';

const mapStateToProps = (state: any) => ({
  login: state.login || {},
  location: state.location,
  groupLocations: state.groupLocations,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetLogin: () => {
    dispatch(resetLoginData());
  },

  fetchLocationDataFromInvoice: (invoiceId: any) => {
    const onSuccess = (response: any) => {
      dispatch(
        apiGetDispatchable(
          getSolvAndUclMergedLocationUrl(response.location_id),
          setActiveLocation,
          locationError(response.location_id)
        )
      );

      return noOp();
    };

    dispatch(apiGetDispatchable(getInvoiceLocationUrl(invoiceId), onSuccess, noOp));
  },

  saveNewInvoiceSource: (params: any) => {
    dispatch(saveNewInvoiceSource(params));
  },

  fetchGroupLocations: (groupId: any) => {
    dispatch(fetchingGroupLocations());
    dispatch(
      apiGetDispatchable(getGroupLocationsUrl(groupId), receiveGroupLocations, groupLocationsError)
    );
  },
});

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(LoginRoute);

const handleBackOnClick = () => history.push('/');

const bookingWidgetFlowLogin = (context: any, params: any) => ({
  chunks: ['login'],

  component: <ConnectedLogin locationId={params.locationId} />,
  accountWrapper: {
    loginRequired: false,
  },
});

const paymentsFlowLogin = (context: any, params: any) => ({
  chunks: ['login'],
  component: (
    <ConnectedLogin
      invoiceId={params.invoiceId}
      locationId={params.locationId}
      shouldCreateAccountIfNotFound
    />
  ),
  accountWrapper: {
    loginRequired: false,
  },
});

const paymentsSelectLogin = (context: any, params: any) => ({
  chunks: ['login'],
  component: (
    <ConnectedLogin
      groupId={params.groupId}
      LayoutProps={{ hideHeader: true }}
      locationId={params.locationId}
      shouldCreateAccountIfNotFound
    />
  ),
  accountWrapper: {
    loginRequired: false,
  },
});

const waitListFlowLogin = (context: any, params: any) => ({
  chunks: ['login'],

  component: <ConnectedLogin bookingId={params.bookingId} LayoutProps={{ handleBackOnClick }} />,
  accountWrapper: {
    loginRequired: false,
  },
});

const symptomsFlowLogin = (context: any) => ({
  chunks: ['login'],

  component: <ConnectedLogin LayoutProps={{ handleBackOnClick }} query={context.query} />,

  accountWrapper: {
    loginRequired: false,
  },
});

const confirmationLogin = () => ({
  chunks: ['login'],

  component: <ConnectedLogin LayoutProps={{ handleBackOnClick }} />,
  accountWrapper: {
    loginRequired: false,
  },
});

const addPreferredPharmacyLogin = (context: any) => ({
  chunks: ['login'],

  component: <ConnectedLogin LayoutProps={{ handleBackOnClick }} query={context.query} />,

  accountWrapper: {
    loginRequired: false,
  },
});

const generalLoginFlow = () => ({
  chunks: ['login'],

  component: <ConnectedLogin LayoutProps={{ handleBackOnClick }} />,
  accountWrapper: {
    loginRequired: false,
  },
});

const loginThenRedirect = (context: any) => ({
  chunks: ['login'],

  component: (
    <ConnectedLogin
      LayoutProps={{ handleBackOnClick }}
      query={context.query}
      redirectToUrl={`/${context.params[0]}`}
    />
  ),

  accountWrapper: {
    loginRequired: false,
  },
});

/**
 * Redirects to the Remix app at a given relative path.
 *
 * <mapp-domain>/account/login/remix/foo/bar => <remix-domain>/foo/bar
 *
 * This is done for security, to disallow a redirect to an arbitrary domain after login.
 */
const loginThenRedirectRemix = (context: any) => ({
  chunks: ['login'],

  component: (
    <ConnectedLogin
      LayoutProps={{ handleBackOnClick }}
      query={context.query}
      redirectToUrl={`/${context.params[0]}`}
      remix
    />
  ),

  accountWrapper: {
    loginRequired: false,
  },
});

const redirectToAccount = (context: any) => {
  const { path } = context;
  history.replace(`/account${path}`);
  return {
    chunks: ['login'],

    component: <LoadingScreen />,
    accountWrapper: {
      loginRequired: false,
    },
  };
};

export {
  addPreferredPharmacyLogin,
  bookingWidgetFlowLogin,
  confirmationLogin,
  generalLoginFlow,
  loginThenRedirect,
  loginThenRedirectRemix,
  paymentsFlowLogin,
  paymentsSelectLogin,
  redirectToAccount,
  symptomsFlowLogin,
  waitListFlowLogin,
};

if (module.hot) {
  module.hot.accept();
}
