import {
  INVOICES_ERROR,
  INVOICES_LIST_RECEIVED,
  INVOICES_LIST_ERROR,
  INVOICES_RECEIVED,
  INVOICE_SAVE_LOCATION_OR_GROUP_ID,
  INVOICE_SET_TRAFFIC_SOURCE,
} from '../constants/index';
import { convertSnakeToCamelCase } from '../core/util/object';

const invoiceSetTrafficSource = (value: any) => ({
  type: INVOICE_SET_TRAFFIC_SOURCE,
  payload: { value },
});

const invoicesError = (value: any) => ({
  type: INVOICES_ERROR,
  payload: { value },
});

const invoicesReceived = (value: any) => ({
  type: INVOICES_RECEIVED,
  payload: { value: convertSnakeToCamelCase(value) },
});

const invoicesListReceived = (value: any) => ({
  type: INVOICES_LIST_RECEIVED,
  payload: { value },
});

const invoicesListError = (value: any) => ({
  type: INVOICES_LIST_ERROR,
  payload: { value },
});

const saveNewInvoiceSource = (value: any) => ({
  type: INVOICE_SAVE_LOCATION_OR_GROUP_ID,
  payload: { value },
});

export {
  invoicesError,
  invoicesReceived,
  invoicesListReceived,
  invoicesListError,
  invoiceSetTrafficSource,
  saveNewInvoiceSource,
};
