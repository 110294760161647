import { NavBar, Stack } from '@solvhealth/jigsaw';
import React, { useEffect } from 'react';
import { LoginController } from '~/components/LoginController';
import SolvPlusLogo from '~/components/Membership/SolvPlusLogo';
import { willRedirectToSolvPlusFlowsAfterLogin } from '~/components/Membership/utils';
import Logo from '~/components/SolvPatternLibrary/Logo';
import BorderBoxReset from '~/components/util/BorderBoxReset';
import { redirectAfterLogin } from '~/core/login';
import withAuthentication from '~/core/login/withAuthentication';
import { LOGIN_ACTION_LOGIN } from '../../constants/index';
import useSetTitle from '../../hooks/useSetTitle';
import history from '~/core/history';
import { NativeFunctions } from '~/core/util/native';
import useSetNativeSafeAreaColor from '~/hooks/useSetNativeSafeAreaColor';
import { colors } from '~/constants/colors';
import { useLDFlags } from '~/core/launch-darkly/hooks/useLDFlags';

type Props = {
  LayoutProps?: object;
  redirectToUrl?: string;
  isLoggedIn: boolean;

  /** Whether the redirect is to the remix app */
  remix?: boolean;
};

const LoginRoute = ({ redirectToUrl, LayoutProps, isLoggedIn, remix, ...LoginProps }: Props) => {
  const { mobileAppLoggedOutLandingPage } = useLDFlags();

  useSetNativeSafeAreaColor(colors.white);

  useEffect(() => {
    if (isLoggedIn) {
      redirectAfterLogin(null, redirectToUrl, false, false, remix);
    }
    // TODO: Suppressing this error because I didn't write this code but it seems right
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSetTitle('Solv - Login for Hassle-free healthcare');

  const isInSolvPlusContext = willRedirectToSolvPlusFlowsAfterLogin();

  return (
    <BorderBoxReset>
      <Stack>
        <NavBar
          onGoBack={() => {
            history.goBack();
            if (!mobileAppLoggedOutLandingPage) {
              // If logged out app feature is on, we don't want to navigate back to landing page when navigating back from login screen.
              // (There will be some other page to navigate back to instead.)
              NativeFunctions.navigateToLandingPage.call('/account/login');
            }
          }}
        >
          {isInSolvPlusContext ? <SolvPlusLogo height={24} /> : <Logo height={24} />}
        </NavBar>

        <LoginController
          defaultAction={LOGIN_ACTION_LOGIN}
          redirectToRemix={remix}
          redirectToUrl={redirectToUrl}
          {...LoginProps}
        />
      </Stack>
    </BorderBoxReset>
  );
};

export default withAuthentication(LoginRoute);
